
import Vue from "vue";
import axios from "@/plugins/axios";
import i18n from "@/i18n";
import { ProductListPayload } from "../../models/productListPayload";

export default Vue.extend({
  name: "ProductList",
  data: () => ({
    dataTable: [],
    years: [],
    selectedYears: null,
    regions: [],
    selectedRegions: [],
    countries: [],
    selectedCountries: [],
    gniTypes: [],
    selectedGNITypes: [],
    brands: [],
    selectedBrands: [],
    manufacturers: [],
    selectedManufacturers: [],
    types: [],
    selectedTypes: [],
    isLoading: true,
  }),
  computed: {
    headers() {
      return [
        {
          text: i18n.t("reports.productlist.yearselected"),
          value: "surveyYear",
        },
        {
          text: i18n.t("reports.productlist.region"),
          value: "region",
        },
        {
          text: i18n.t("reports.productlist.country"),
          value: "country",
        },
        {
          text: i18n.t("reports.productlist.gni"),
          value: "gni",
        },
        {
          text: i18n.t("reports.productlist.type"),
          value: "type",
        },
        {
          text: i18n.t("reports.productlist.brand"),
          value: "brand",
        },
        {
          text: i18n.t("reports.productlist.manufactured"),
          value: "manufacturer",
        },
        {
          text: i18n.t("reports.productlist.price"),
          value: "price",
        },
        {
          text: i18n.t("reports.productlist.currency"),
          value: "currency",
        },
        {
          text: i18n.t("reports.productlist.taxInclude"),
          value: "taxIncluded",
        },
        {
          text: i18n.t("reports.productlist.taxrate"),
          value: "taxRate",
        },
      ];
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      const form: ProductListPayload = {
        regions: this.selectedRegions,
        countries: this.selectedCountries,
        gnis: this.selectedGNITypes,
        year: this.selectedYears,
        types: this.selectedTypes,
        brands: this.selectedBrands,
        manufacturers: this.selectedManufacturers,
      };
      axios
        .post(`ProductList/Search`, form)
        .then((res: any) => {
          this.dataTable = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      const form: ProductListPayload = {
        regions: this.selectedRegions,
        countries: this.selectedCountries,
        gnis: this.selectedGNITypes,
        year: this.selectedYears,
        types: this.selectedTypes,
        brands: this.selectedBrands,
        manufacturers: this.selectedManufacturers,
      };
      axios
        .post(`ProductList/Search/export`, form, { responseType: "blob" })
        .then((res) => {
          const fileName = `Product List`;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
    loadFilters() {
      axios
        .get(`MasterQuery/years`)
        .then((res) => {
          this.years = res.data;
          this.selectedYears = this.years.length > 0 ? this.years[0] : null;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
      axios
        .get(`ProductList/Regions`)
        .then((res) => {
          this.regions = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
      axios
        .get(`ProductList/Countries`)
        .then((res) => {
          this.countries = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
      axios
        .get(`ProductList/GNIType`)
        .then((res) => {
          this.gniTypes = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
      axios
        .get(`ProductList/Types`)
        .then((res) => {
          this.types = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
      axios
        .get(`ProductList/Brands`)
        .then((res) => {
          this.brands = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
      axios
        .get(`ProductList/Manufacturers`)
        .then((res) => {
          this.manufacturers = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
  },
  created() {
    this.loadFilters();
  },
  watch: {
    selectedYears() {
      this.getData();
    },
    selectedRegions() {
      this.getData();
    },
    selectedCountries() {
      this.getData();
    },
    selectedBrands() {
      this.getData();
    },
    selectedTypes() {
      this.getData();
    },
    selectedGNITypes() {
      this.getData();
    },
    selectedManufacturers() {
      this.getData();
    },
  },
});
